<template>
  <div>
    <div class="card mt-2">
      <div class="card-body">
        <h3 class="text-primary text-center">Edit Existing Vendor</h3>
        <hr />
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="name">Name<span class="text-danger">*</span> :</label>
              <input
                type="tetx"
                class="form-control"
                id="name"
                placeholder="type vendor name.."
                v-model="name"
              />
            </div>
            <div class="form-group">
              <label for="vendor_image">Vendor Image :</label>
              <input
                type="file"
                name="file"
                ref="file"
                class="form-control-file"
                id="vendor_image"
                @change="onChange"
              />
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <img
                   v-if="item.icon_image_normal !== null" 
                   @error="handleImageError($event)"
                    class="rounded"
                    style="width: 60%"
                    :src="imagesrc"
                    alt="Vendor Image"
                  />
                  <img v-else class="rounded" style="width: 60%; border:1px solid #ccc;" :src="imagePath"
                alt="Vendor Image" />
                </div>
              </div>
              <div class="col-sm-6">
                <div v-if="previewImage">
                  <div class="form-group">
                    <img
                      class="rounded shadow"
                      style="width: 60%"
                      :src="previewImage"
                      alt="Vendor Image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="organization"
                >Organization<span class="text-danger">*</span> :</label
              >
              <multiselect
                v-model="organization"
                label="name"
                track-by="name"
                placeholder="type to find organization"
                open-direction="bottom"
                :options="items_organization"
                :searchable="true"
                :loading="isLoadingSearch"
                :close-on-select="true"
                @search-change="asyncFindOrg"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>
            <div class="form-group">
              <label for="isExternal">Vendor Type :</label>
              <b-form-select
                class="form-control"
                id="isExternal"
                v-model="isExternal"
                :options="vendorType"
              >
              </b-form-select>
            </div>
            <div class="form-group">
              <label for="is_vendorSource">Vendor Source :</label>
              <b-form-select
                class="form-control"
                id="is_vendorSource"
                v-model="vendorSource"
                :options="optionsVendorSource"
              >
              </b-form-select>
            </div>
            <div class="form-group">
              <label for="status">Status :</label>
              <b-form-select
                class="form-control"
                id="status"
                v-model="status"
                :options="options"
              >
              </b-form-select>
            </div>
            <div class="form-group">
              <label for="vendor_id">Vendor ID From AX :</label>
              <input
                type="text"
                class="form-control"
                id="vendor_id"
                placeholder="type vendor ID from AX.."
                v-model="vendorIdAx"
              />
            </div>
            <div class="form-group">
              <label for="description">Description :</label>
              <textarea
                class="form-control"
                id="description"
                rows="4"
                placeholder="type description.."
                v-model="description"
              ></textarea>
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="is_active"
                v-model="isActive"
              />
              <label class="form-check-label" for="is_active">Is Active</label>
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="is_active"
                v-model="isContentBooks"
              />
              <label class="form-check-label" for="isContentBooks">Is Content Books</label>
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="isContentMagazines"
                v-model="isContentMagazines"
              />
              <label class="form-check-label" for="isContentMagazines">Is Content Magazines</label>
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="isContentNewspapers"
                v-model="isContentNewspapers"
              />
              <label class="form-check-label" for="isContentNewspapers">Is Content Newspapers</label>
            </div>
         
            <br /><br />
            <button
              type="submit"
              class="btn btn-primary btn-block"
              @click="updateVendor()"
            >
              <i class="fa fa-pencil"></i>
              Update Vendor
            </button>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';
import defaultImage from '../../../src/assets/img/default.jpg'; 

const vendorStatus = Object.entries(constant.VENDOR_STATUS).map((val) => ({
  text: val[0].replace(/_/g, ' '),
  value: val[1],
}));
const vendorType = Object.entries(constant.VENDOR_TYPE).map((val) => ({
  text: val[0].replace(/_/g, ' '),
  value: val[1],
}));

export default {
  name: 'EditVendor',
  data() {
    return {
      name: '',
      organization: '',
      items_organization: [],
      vendorIdAx: '',
      description: '',
      isActive: true,
      isContentBooks:false,
      isContentMagazines:false,
      imagePath: defaultImage,
      isContentNewspapers:false,
      isLoadingSearch: false,
      status: '1',
      vendorSource:'',
      isExternal :false,
      options: [...vendorStatus],
      vendorType: [...vendorType],
      optionsVendorSource: [
      { 
        value: 'Gramedia Digital',
        text: 'Gramedia Digital' 
      },
      {
        value: 'SmartLib Client',
        text: 'SmartLib Client',
      },
      ],
      file: null,
      previewImage: null,
      imagesrc: '',
    };
  },
  computed: {
    ...mapState({
      item: (state) => state.vendors.item,
      isError: (state) => state.vendors.isError,
      successMessage: (state) => state.vendors.successMessage,
      errorMessage: (state) => state.vendors.errorMessage,
    }),
  },
  watch: {
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.getVendor();
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/vendor');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
  },
  created: function() {
    const vendorID = this.$route.params.id;
    this.fetchVendorsById({ id: vendorID });
  },
  methods: {
    ...mapActions('organization', ['searchOrganization']),
    ...mapActions('vendors', ['fetchVendorsById', 'editVendor']),

    asyncFindOrg(keyword) {
      if (!keyword) return;
      this.isLoadingSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          search: keyword,
        };

        this.searchOrganization(payload)
          .then((response) => {
            this.items_organization = response.data.data;
            this.isLoadingSearch = false;
          })
          .catch(() => {
            this.isLoadingSearch = false;
          });
      }, 600);
    },

    async getVendor() {
      const data = this.item;
      this.name = data.name;
      this.status = data.vendor_status;
      this.isExternal = data.is_external
      this.description = data.description;
      this.isActive = data.is_active;
      this.isContentBooks = data.isContentBooks;
      this.vendorSource = data.vendor_source;
      this.isContentMagazines = data.isContentMagazines;
      this.isContentNewspapers = data.isContentNewspapers
      this.vendorIdAx = data.sync_vendor ? data.sync_vendor.vendor_id_ax : '';
      this.organization = data.cas_organization;
      this.imagesrc =
        constant.IMG_URL_STATIC_VENDOR.URL + data.icon_image_normal;
    },

    async updateVendor() {
      const vendorID = this.$route.params.id;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('name', this.name);
      formData.append('organization', this.organization.id);
      formData.append('isExternal', this.isExternal);
      formData.append('status', this.status);
      formData.append('vendorSource',this.vendorSource)
      formData.append('vendorIdAx', this.vendorIdAx);
      formData.append('description', this.description);
      formData.append('isActive', this.isActive);
      formData.append('isContentBooks', this.isContentBooks);
      formData.append('isContentMagazines', this.isContentMagazines)
      formData.append('isContentNewspapers', this.isContentNewspapers)
      let payload = {
        id: vendorID,
        formData: formData,
      };
      this.editVendor(payload);
    },
    

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },

    handleImageError(event) {
      event.target.src = this.imagePath;
    },

    onChange(e) {
      this.file = e.target.files[0];
      let input = this.$refs.file;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit('input', file[0]);
      }
    },
  },
};
</script>
